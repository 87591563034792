import React, { useState } from "react"
import CreateSubscription from "../components/MyPage/CreateSubscription"

const Subscription = () => {
  const props = {
    unit: process.env.GATSBY_APP_ORDERLINE_UNIT,
    priceLevel: process.env.GATSBY_APP_ORDER_PRICELEVEL,
    name: process.env.GATSBY_APP_ORDER_NAME_HU,
    orderLine: process.env.GATSBY_APP_ORDERLINE_HU_SUB,
    typeMembership: "huinn",
    source: "MinSide",
    productNumber: "HUM",
  }

  return <CreateSubscription {...props} />
}

export default Subscription
